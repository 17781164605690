import React from 'react'

// App components
import ContentAndSignupForm from '../../../components/ContentAndSignupForm'

// App content
import fivethingsInvisalign from '../../../assets/invis-5things.png'

class Page extends React.Component {
  render() {
    const content = (
      <div>
        <p css={{ marginBottom: 0 }}>
          Are you considering braces for yourself or your child?
        </p>
        <h2>Top 5 Things You Need To Know About Invisalign</h2>
        <p>
          You’ve probably heard of Invisalign®, the clear removable trays that
          many of our patients here in Rochester, MN use to straighten their
          teeth with minimal changes to their lifestyle. With the Invisalign
          System, Dr. Collins can move your teeth gradually without the
          complications of metal brackets and wires.
        </p>
        <p>
          Although Invisalign is a wonderful tool that orthodontists can use to
          straighten your teeth, there are several things you MUST KNOW prior to
          starting Invisalign treatment. If you decide to start Invisalign
          treatment without knowing these items in advance, your treatment
          outcome may not only end up less than ideal, but it could end up
          destructive to the health of your teeth and smile.
        </p>
      </div>
    )
    return (
      <div>
        <ContentAndSignupForm
          thumbnail={fivethingsInvisalign}
          title={'Top 5 Things You Need To Know About Invisalign'}
          content={content}
          configKey="invisalignFiveThings"
          captchaId="recaptcha_db94df5cc26343aa05cd1b3762fda94c"
        />
      </div>
    )
  }
}

export default Page
